<template>

<com-content :grow="true" :blue="true" :center="true" class="success">

	<i class="fa-duotone fa-party-horn"></i>
	<h1>Awesome - we can't wait to see what you do!</h1>
	<p>We will approve your account within 48 hours, so keep an eye on your emails!</p>

</com-content>
	
</template>

<script>

import JSConfetti from 'js-confetti'

export default {

	components: {

		'com-content': () => import('./common/Content')

	},

	data: function() {

		return {
			confetti: false,
			login: process.env.VUE_APP_LOGIN
		}

	},

	created: function() {

		this.$nextTick(function() {

			window.scrollTo(0, this.$refs.inner.getBoundingClientRect().top)

			this.confetti = new JSConfetti()

			this.confetti.addConfetti({
				confettiRadius: 3,
				confettiNumber: 500,
			})

		}.bind(this))

	}

}

</script>

<style scoped>

.success {
	padding: 120px 10px 80px 10px;
	color: #fff;
}

.success i {
	font-size: 96px;
	margin-bottom: 40px;
	--fa-secondary-color: #ffbe7a;
	--fa-secondary-opacity: 1;
}

.success h1 {
	font-size: 28px;
	text-align: center;
	margin-bottom: 10px;
	font-weight: 500;
}

.is-mobile .success h1 {
	font-size: 24px;
	line-height: 28px;
}

.success p {
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	font-weight: 300;
}

.success a {
	font-size: 18px;
	color: #fff;
	font-weight: bold;
	padding: 15px 40px;
	margin-top: 40px;
	display: inline-block;
	text-transform: uppercase;
	background-color: #ffbe7a;
	letter-spacing: 1px;
	border-radius: 4px;
	font-weight: 600;
    color: #333;
	transition: transform 100ms linear;
}

.is-desktop .success a:hover {
	transform: scale(1.05);
}

.is-mobile .success a {
	font-size: 14px;
	padding: 15px 20px;
}

</style>